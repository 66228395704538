import React, { useState, useEffect } from "react";
import { useEventID } from "wecall-config-lib";
import logo from "../assets/logo.png";
import heroimage from "../assets/c836c363-2bde-11ee-b5e1-06326af95a41_e99f86c93b01379314b774d4475ee07a005911e1_oprah news2.gif";
import { Link, Outlet, Route, Routes, useLocation } from "react-router-dom";
import arrow from "../assets/arrow.svg";
import testimonial from "../assets/testimonials.png";
// import user1 from '../assets/user-1.jpeg';
// import user2 from '../assets/user-2.jpeg';
// import user3 from '../assets/user-3.jpeg';
// import user4 from '../assets/user-4.jpeg';
import InitialScripts from "../script/initialScripts";
// import FloatingCard from "../floating-card";
import trustseal from "../assets/trust-seal.png";
import {
  useGeneratorQuery,
  RINGBA_STORAGE_KEYS,
  useRingba,
  useInitRingba,
} from "wecall-config-lib";
import { useNavigate } from "react-router-dom";
import { Timer } from "../App";
import QuizInitialScripts from "../script/quizInitialScript";
import { ROUTES } from "../App";

const FloatingCard = React.lazy(() => import("../floating-card"));

const routes = {
  congrats: "congratsVisited",
  income: "incomeVisited",
  medicareMedicaid: "medicareMedicaidVisited",
  lander: "landerVisited",
};

export function MedicareMedicaidQuizLander({ headline, nextPageRoute }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();
  const navigate = useNavigate();

  const setValues = (value) => {
    // sessionStorage.setItem(RINGBA_STORAGE_KEYS.medicareMedicaid, value);
    sessionStorage.setItem("consolidate", value);
    window._rgba_tags = window._rgba_tags || [];
    window._rgba_tags.push({ ["consolidate"]: value });
  };

  const externalRedirectLink = window.domain_settings.medicareMedicAidYesLink;

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.income))
      return navigate({
        pathname: "../" + ROUTES.income,
        search: generatorQuery.get(),
      });
    sessionStorage.setItem(routes.medicareMedicaid, true);
  }, []);
  return (
    <>
      <div className="headline-wrapper">
        <h1>{headline}</h1>
      </div>
      <div className="left-side">
        <div className="question">
          <h2>Want to consolidate your debt and simplify your life?</h2>

          <Link
            onClick={() => setValues("yes")}
            id="medicare_medicaid_yes"
            to={{ pathname: nextPageRoute, search: generatorQuery.get() }}
          >
            Yes
          </Link>

          <Link
            onClick={() => setValues("no")}
            id="medicare_medicaid_no"
            to={"https://rtrackt.com/clickout/23041/311260/?"}
          >
            No
          </Link>
        </div>
      </div>
    </>
  );
}

export function IncomeQuizLander({ headline, nextPageRoute }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();

  const setValues = (value) => {
    // sessionStorage.setItem(RINGBA_STORAGE_KEYS.income, value);
    sessionStorage.setItem("10k", value);
    // storeRgbaData(RINGBA_STORAGE_KEYS.income, value);
    window._rgba_tags = window._rgba_tags || [];
    window._rgba_tags.push({ ["10k"]: value });
  };

  const navigate = useNavigate();

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.lander))
      return navigate({ pathname: "../", search: generatorQuery.get() });
    sessionStorage.setItem(routes.income, true);
  }, []);
  return (
    <>
      <div className="headline-wrapper">
        <h1>{headline}</h1>
      </div>
      <div className="left-side">
        <div className="question">
          <h2>Do you have over $10,000 in credit card debt?</h2>

          <Link
            // onClick={() => setValues("- - 50")}
            onClick={() => setValues("yes")}
            to={{
              pathname: nextPageRoute,
              search: generatorQuery.get(),
            }}
          >
            Yes
          </Link>
          <Link
            // onClick={() => setValues("50 - +")}
            onClick={() => setValues("no")}
            to={"https://rtrackt.com/clickout/23041/311260/?"}
          >
            No
          </Link>
        </div>
      </div>
    </>
  );
}

export function CTAQuizLander({ headline, subtitle }) {
  const generatorQuery = useGeneratorQuery();

  useEffect(() => {
    // generatorQuery.set(window.location.search);
    sessionStorage.setItem(routes.lander, true);
  }, []);
  return (
    <>
      <div className="headline-wrapper">
        <h1>{headline}</h1>
      </div>
      <div className="sub-hero-section">
        <div className="left-side">
          <h2>{subtitle}</h2>

          <div className="desktop-cta">
            <Link
              id="cta-click"
              to={{ pathname: "10k", search: generatorQuery.get() }}
            >
              I Want My Debt Removed
              <img src={arrow} alt=""></img>
            </Link>
            <span>37 Available Slots Left, Act Now!</span>
          </div>
        </div>

        <div className="right-side">
          <img src={heroimage}></img>
        </div>
        <div className="mobile-cta">
          <Link
            id="cta-click"
            to={{ pathname: "10k", search: generatorQuery.get() }}
          >
            I Want My Debt Removed
            <img src={arrow} alt=""></img>
          </Link>
          <span>37 Available Slots Left, Act Now!</span>
        </div>
      </div>
    </>
  );
}

export function CongratsQuizLander({ number, headline }) {
  const navigate = useNavigate();
  const { getEventId } = useEventID();

  const generatorQuery = useGeneratorQuery();

  const triggerContactEvent = () => {
    window.fbcFunc("track", "Contact", {
      eventID: getEventId(),
    });
  };

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.medicareMedicaid))
      return navigate({
        pathname: "../" + ROUTES.medicareMedicaid,
        search: generatorQuery.get(),
      });
    sessionStorage.setItem(routes.congrats, true);
  }, []);

  return (
    <>
      <div className="left-side">
        <div className="congrats">
          <h2>{headline}</h2>
          <p>
            You’re prequalified for relief from debt. Tap the button below to
            call and claim.
          </p>
          <a
            onClick={triggerContactEvent}
            href={"tel:" + number}
            className="call_now"
          >
            {number}
          </a>
          <p>
            This program has extremely limited capacity, so call us in the next{" "}
            <Timer /> to claim your spot before it's given to another applicant
            in line.
          </p>
          <img src={trustseal} alt=""></img>
        </div>
      </div>
    </>
  );
}

function MobileNumberLoader({ setNumber }) {
  const testRingba = window.domain_settings.rinbaUser;

  let ringba = testRingba;
  const generator = ringba.user;
  const { number } = useInitRingba({
    ringbaKey: {
      key: testRingba.key,
      number: testRingba.number,
      user: testRingba.user,
    },
  });

  useEffect(() => {
    setNumber(number);
  }, [number]);
  return <></>;
}

export default function QuizLander({ setNumber, cityStateResponse }) {
  const location = useLocation();

  return (
    <>
      <QuizInitialScripts setNumber={setNumber} />
      {location.pathname.includes(ROUTES.income) ||
      location.pathname.includes(ROUTES.medicareMedicaid) ||
      location.pathname.includes(ROUTES.congrats) ||
      location.pathname.includes(ROUTES.healthInsurance) ||
      location.pathname.includes(ROUTES.phoneNumber) ? (
        <MobileNumberLoader setNumber={setNumber} />
      ) : undefined}
      <div className="App">
        <div className="mainWrapper">
          <header>
            <div className="container">
              <div className="row">
                <div className="logo-wrapper">
                  <a href="#">
                    <img src={logo}></img>
                  </a>
                </div>
              </div>
            </div>
          </header>
          <div className="hero-section">
            <div className="container">
              <div className="row">
                <Outlet />
                <div className="logo-strip">
                  <div className="logoitem">
                    {
                      //  <img src={newslogo1}></img>
                      //  <img src={newslogo2}></img>
                      //  <img src={newslogo3}></img>
                      //  <img src={newslogo4}></img>
                      //  <img src={newslogo5}></img>
                      //  <img src={newslogo6}></img>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section1">
            <div className="container">
              <div className="row">
                <div className="section-1-content">
                  <h1>Transforming Lives: The Impact of the Debt Reduction</h1>
                  <h2>
                    The New Debt Reduction Plan is designed to help those
                    struggling with bills. If inflation has impacted your
                    finances, this is the chance to cut up to $80,000 from your
                    debt.
                  </h2>
                  <h2>Act now and call our team for financial relief.</h2>
                  <div className="item-benefits">
                    <ul>
                      <li>
                        <div className="benefit">
                          {/* <img src={"/assets/003-food.png"}></img> */}
                          <h2>$43 Million</h2>
                          <p>
                            The ripple effect of financial empowerment is clear,
                            with substantial debt relief provided to households
                            across the nation.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="benefit">
                          {/* <img src={"/assets/001-key.png"}></img> */}
                          <h2>250,000+</h2>
                          <p>
                            Many lives have been touched, with more families
                            joining every day to avail the comprehensive
                            benefits.
                          </p>{" "}
                        </div>
                      </li>
                      <li>
                        <div className="benefit">
                          {/* <img src={"/assets/002-gas-station.png"}></img> */}
                          <h2>1 in 3</h2>
                          <p>
                            Reflecting the widespread need, a third of the
                            population qualifies for this life-enhancing
                            program, ensuring no one is left behind.
                          </p>{" "}
                        </div>
                      </li>
                      <li>
                        <div className="benefit">
                          {/* <img src={"/assets/004-bill.png"}></img> */}
                          <h2>$482 Seats</h2>
                          <p>
                            With limited spots remaining, urgency meets
                            opportunity for those aiming to harness the
                            program's full potential.
                          </p>{" "}
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="cta-holder">
                    <Link to={"10k"} id="cta-click">
                      I Want My Debt Removed <img src={arrow} alt=""></img>
                    </Link>
                    <span>37 Available Slots Left, Act Now!</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section1">
            <div className="container">
              <div className="row">
                <div className="section-1-content">
                  <h1>Why was this debt relief program created?</h1>
                  <h2>
                    Since the pandemic, prices have surged, increasing the cost
                    of essentials like food and utilities. This new program aims
                    to support families facing these financial challenges and
                    provide relief from the burden of inflation.
                  </h2>

                  <div className="cta-holder">
                    <Link to={"10k"} id="cta-click">
                      I Want My Debt Removed <img src={arrow} alt=""></img>
                    </Link>
                    <span>37 Available Slots Left, Act Now!</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="container">
              <div className="row">
                <div className="testimonial-headline">
                  <h1>Satisfied Citizens Share Their Stories!</h1>
                  <img src={testimonial} alt="" />
                </div>
                <div className="testimonial-content-holder">
                  <h3 className="h6">
                    We strive to offer you the best service, as evidenced by our
                    Trustpilot star rating.
                  </h3>
                </div>
                <div className="testimonial-list">
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img src={"/assets/user-1.jpeg"}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img src={"/assets/star.png"} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>Floyd Miles</h3>
                        <p>
                          The Debt Relief Program provided exceptional service.
                          They guided me through every step of the debt
                          reduction process and helped relieve a significant
                          financial burden. I can’t thank them enough for their
                          support.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img src={"/assets/user-2.jpeg"}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img src={"/assets/star.png"} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>Michael Anderson</h3>
                        <p>
                          Thanks to the Debt Reduction Program, I can now relax
                          knowing my debt is under control. The team was
                          professional and attentive, providing the peace of
                          mind I needed.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img src={"/assets/user-4.jpeg"}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img src={"/assets/star.png"} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>Olivia Martinez</h3>
                        <p>
                          I received personalized and effective support from the
                          Debt Reduction Program. The reduction in my debt was
                          beyond my expectations, and I feel much more confident
                          about my financial future.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img src={"/assets/user-3.jpeg"}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img src={"/assets/star.png"} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>David Wilson</h3>
                        <p>
                          The Debt Reduction Program did a fantastic job helping
                          me reduce my debt quickly and effectively. I’m very
                          thankful for their commitment and the care they showed
                          throughout the process.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="container">
              <div className="row"></div>
            </div>
          </div>
        </div>
      </div>
      <React.Suspense fallback={<></>}>
        <FloatingCard />
      </React.Suspense>
      <LanderSocialEventTrigger />
    </>
  );
}

function LanderSocialEventTrigger() {
  const { getEventId } = useEventID();

  const viewContentKey = "PageView";
  const viewContentKey2 = "PageView2";

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  useEffect(() => {
    if (window.fbcFunc) {
      window.fbcFunc("track", "PageView", { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey, "true");
    }

    if (window.tikTokEvent && params.get("utm_source") === "tiktok") {
      window.tikTokEvent.track("ViewContent", {}, { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey2, "true");
    }
  }, []);
  return <></>;
}
