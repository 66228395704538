import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import React, { useRef, useState } from "react";
import "./App.scss";
import DefaultPage from "./pages/default";
import {
  RINGBA_STORAGE_KEYS,
  sessionStorageKeys,
  useEventID,
  useGeneratorQuery,
  useRingba,
} from "wecall-config-lib";
import { useEffect } from "react";
import arrow from "../src/assets/arrow.svg";
import axios from "axios";
import QuizLander, {
  CTAQuizLander,
  CongratsQuizLander,
  IncomeQuizLander,
  MedicareMedicaidQuizLander,
} from "./pages/quiz";
import { PropagateLoader } from "react-spinners";
import { useInitRingba } from "wecall-config-lib";

window._rgba_tags = window._rgba_tags || [];

const routes = {
  congrats: "congratsVisited",
  income: "incomeVisited",
  medicareMedicaid: "medicareMedicaidVisited",
  lander: "landerVisited",
};

export const ROUTES = {
  congrats: "congrats",
  income: "10k",
  medicareMedicaid: "consolidate",
  healthInsurance: "health-insurance",
  phoneNumber: "phone-number",
};

function GetRingbaNumber({ setNumber }) {
  const [ringbaUser, setRingbaUser] = useState(
    window.domain_settings.rinbaUser
  );
  const testRingba = window.domain_settings.rinbaUser;

  const { number } = useInitRingba({
    ringbaKey: {
      key: testRingba.key,
      number: testRingba.number,
      user: testRingba.user,
    },
  });
  useEffect(() => {
    setNumber(number);
  }, [number]);
  return <></>;
}

function Congrats({ headline }) {
  const navigate = useNavigate();
  const { getEventId } = useEventID();
  const [number, setNumber] = useState();

  const generatorQuery = useGeneratorQuery();

  const triggerContactEvent = () => {
    window.fbcFunc("track", "Contact", {
      eventID: getEventId(),
    });
  };

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.medicareMedicaid))
      return navigate({
        pathname: "../" + ROUTES.medicareMedicaid,
        search: generatorQuery.get(),
      });
    sessionStorage.setItem(routes.congrats, true);
  }, []);

  return (
    <>
      <GetRingbaNumber setNumber={setNumber} />

      <div className="left-side">
        <div className="congrats">
          <h2>{headline}</h2>
          <p>Space is limited. Tap the button below to call and claim.</p>

          <a
            onClick={triggerContactEvent}
            href={
              "tel:" +
              (window.pnumber || window.domain_settings.rinbaUser?.number)
            }
            className="call_now"
          >
            {window.pnumber || window.domain_settings.rinbaUser?.number}
          </a>
          <p>
            This program has extremely limited capacity, so call us in the next{" "}
            <Timer /> to claim your subsidy before it's given to another
            applicant in line.
          </p>
          <img src={"/assets/trust-seal.png"} loading="lazy" alt=""></img>
        </div>
      </div>
    </>
  );
}

function MedicareMedicaid({ headline, nextPageRoute }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();
  const navigate = useNavigate();

  const setValues = (value) => {
    sessionStorage.setItem(RINGBA_STORAGE_KEYS.medicareMedicaid, value);
    window._rgba_tags = window._rgba_tags || [];
    window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.medicareMedicaid]: value });
  };

  const externalRedirectLink = window.domain_settings.medicareMedicAidYesLink;

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.income))
      return navigate({
        pathname: "../" + ROUTES.income,
        search: generatorQuery.get(),
      });
    sessionStorage.setItem(routes.medicareMedicaid, true);
  }, []);
  return (
    <>
      <div className="headline-wrapper">
        <h1>{headline}</h1>
      </div>
      <div className="left-side">
        <div className="question">
          <h2>Are you on Medicare or Medicaid?</h2>

          <Link
            onClick={() => setValues("yes")}
            id="medicare_medicaid_yes"
            to={externalRedirectLink}
          >
            Yes
          </Link>

          <Link
            onClick={() => setValues("no")}
            id="medicare_medicaid_no"
            to={{ pathname: nextPageRoute, search: generatorQuery.get() }}
          >
            No
          </Link>
        </div>
      </div>
    </>
  );
}

function Income({ headline, nextPageRoute }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();

  const setValues = (value) => {
    // sessionStorage.setItem(RINGBA_STORAGE_KEYS.income, value);
    // storeRgbaData(RINGBA_STORAGE_KEYS.income, value);
    sessionStorage.setItem("healthInsurance", value);

    window._rgba_tags = window._rgba_tags || [];
    // window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.income]: value });
    window._rgba_tags.push({ ["healthInsurance"]: value });
  };

  const navigate = useNavigate();

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.lander))
      return navigate({ pathname: "../", search: generatorQuery.get() });
    sessionStorage.setItem(routes.income, true);
  }, []);
  return (
    <>
      <div className="headline-wrapper">
        <h1>{headline}</h1>
      </div>
      <div className="left-side">
        <div className="question">
          {/* <h2>Do you make less than $50k/Yr?</h2> */}
          <h2>Do you make less than $50k/Yr?</h2>

          <Link
            // onClick={() => setValues("- - 50")}
            onClick={() => setValues("yes")}
            to={{
              pathname: nextPageRoute,
              search: generatorQuery.get(),
            }}
          >
            Yes
          </Link>
          <Link
            // onClick={() => setValues("50 - +")}
            onClick={() => setValues("no")}
            to={"https://rtrackt.com/clickout/23041/311260/?"}
          >
            No
          </Link>
        </div>
      </div>
    </>
  );
}

function HealthInsurance({ headline, nextPageRoute }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();

  const setValues = (value) => {
    sessionStorage.setItem(RINGBA_STORAGE_KEYS.income, value);

    window._rgba_tags = window._rgba_tags || [];
    window._rgba_tags.push({ ["healthInsurance"]: value });
  };

  const navigate = useNavigate();

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.lander))
      return navigate({ pathname: "../", search: generatorQuery.get() });
    sessionStorage.setItem(routes.income, true);
  }, []);
  return (
    <>
      <div className="headline-wrapper">
        <h1>{headline}</h1>
      </div>
      <div className="left-side">
        <div className="question">
          <h2>Do you have health insurance?</h2>
          <Link
            onClick={() => setValues("yes")}
            to={{
              pathname: nextPageRoute,
              search: generatorQuery.get(),
            }}
          >
            Yes
          </Link>
          <Link
            onClick={() => setValues("no")}
            to={{
              pathname: nextPageRoute,
              search: generatorQuery.get(),
            }}
          >
            No
          </Link>
        </div>
      </div>
    </>
  );
}

function PhoneNumber({ headline, nextPageRoute }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");

  const setValues = (value) => {
    // sessionStorage.setItem(RINGBA_STORAGE_KEYS.income, value);
    // window._rgba_tags = window._rgba_tags || [];
    // window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.phoneNumber]: value });
  };

  const navigate = useNavigate();

  const onSubmit = async () => {
    try {
      setError(false);
      setLoading(true);
      setSubmitted(true);
      setApiErrorMessage("");

      const { data } = await axios.get(
        "https://wecalllongfunnel.improveourcredit.com?phone=" + phoneNumber
      );
      if (data.valid === false) {
        setError(true);
        if (data.message) {
          setApiErrorMessage(data.message);
        }
      } else {
        sessionStorage.setItem(RINGBA_STORAGE_KEYS.mobile, phoneNumber);
        window._rgba_tags = window._rgba_tags || [];
        window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.mobile]: phoneNumber });
        navigate(nextPageRoute);
      }
    } catch (error) {
      setError(true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (event) => {
    if (event.target.value.length > 14) return;

    setSubmitted(false);
    const value = event.target.value;

    const rawValue = value.replace(/\D/g, "");

    let formatted = rawValue;
    if (rawValue.length > 3 && rawValue.length <= 6) {
      formatted = `${rawValue.replace(/^(\d{3})(\d+)/g, "($1) $2")}`;
    } else if (rawValue.length > 6) {
      formatted = `${rawValue.replace(/^(\d{3})(\d{3})(\d+)/g, "($1) $2-$3")}`;
    }

    setFormattedPhoneNumber(formatted);
    setPhoneNumber(rawValue);
  };

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.lander))
      return navigate({ pathname: "../", search: generatorQuery.get() });
    sessionStorage.setItem(routes.income, true);
  }, []);

  const validator = () => {
    if (error) return "Phone number is not valid.";
    if (submitted && formattedPhoneNumber.length < 14)
      return "Please enter 10 digit mobile number.";
  };
  return (
    <>
      <div className="headline-wrapper">
        <h1>{headline}</h1>
      </div>
      <div className="left-side">
        <div className="question">
          <h2>Enter your phone number</h2>
          <input
            type="text"
            placeholder="Phone Number"
            onChange={onChange}
            value={formattedPhoneNumber}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSubmit();
              }
            }}
          />
          <em className="error">{apiErrorMessage || validator()}</em>
          <a
            href="javascript:void(0)"
            onClick={onSubmit}
            to={{
              pathname: nextPageRoute,
              search: generatorQuery.get(),
            }}
          >
            {loading ? (
              <PropagateLoader
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "33.5px",
                }}
                color={"#fff"}
                size={10}
              />
            ) : (
              "Submit"
            )}
          </a>
        </div>
      </div>
    </>
  );
}

function CTA({ headline, subtitle }) {
  const generatorQuery = useGeneratorQuery();

  useEffect(() => {
    // generatorQuery.set(window.location.search);
    sessionStorage.setItem(routes.lander, true);
  }, []);
  return (
    <>
      <div className="headline-wrapper">
        <h1>{headline}</h1>
      </div>
      <div className="sub-hero-section">
        <div className="left-side">
          <h2>{subtitle}</h2>

          <div className="desktop-cta">
            <Link
              id="cta-click"
              to={{ pathname: "income", search: generatorQuery.get() }}
            >
              I Want My Debt Removed
              <img src={"/assets/arrow.svg"} alt=""></img>
            </Link>
            <span>37 Available Slots Left, Act Now!</span>
          </div>
        </div>

        <div className="right-side">
          <img
            loading="lazy"
            src={
              "/assets/c836c363-2bde-11ee-b5e1-06326af95a41_e99f86c93b01379314b774d4475ee07a005911e1_oprah news.gif"
            }
          ></img>
        </div>
        <div className="mobile-cta">
          <Link
            id="cta-click"
            to={{ pathname: "income", search: generatorQuery.get() }}
          >
            I Want My Debt Removed
            <img src={arrow} alt=""></img>
          </Link>
          <span>37 Available Slots Left, Act Now!</span>
        </div>
      </div>
    </>
  );
}

function App() {
  const [number, setNumber] = React.useState("");
  const eventID = useEventID();
  const generatorQuery = useGeneratorQuery();

  const [cityStateResponse, setStateCityResponse] = useState({
    city: "",
    state: "",
    country: "",
    zip: "",
  });

  const cityAddress = async () => {
    const options = {};
    try {
      const { data } = await axios.get(
        "https://funnel.improveourcredit.com/ip?key=askdjaslkdjaskjdsla"
      );
      const state = data.subdivisions[0].isoCode;
      const city = data.city.names.en;
      const country = data.country.names.en;
      const postalCode = data.postal.code;

      localStorage.setItem(sessionStorageKeys.zip, postalCode);
      localStorage.setItem(sessionStorageKeys.city, city);
      localStorage.setItem(sessionStorageKeys.state, state);
      setStateCityResponse({ state, city, country, zip: postalCode });

      window._rgba_tags = window._rgba_tags || [];
      window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.zip]: postalCode });
      window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.city]: city });
      window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.state]: state });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    eventID.getEventId();
    cityAddress();
    generatorQuery.set(window.location.search);

    const doc = window.document.getElementById("spin-loader");
    if (doc) {
      doc.remove();
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <DefaultPage
              cityStateResponse={cityStateResponse}
              setNumber={setNumber}
            />
          }
        >
          <Route
            index
            element={
              <CTA
                subtitle={`Americans in ${cityStateResponse.city}, ${cityStateResponse.state}, have only 3 days to claim their FREE $6400* Subsidy which they can use for Grocery, Rent, Gas and More!`}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.income}
            element={
              <Income
                nextPageRoute={"../" + ROUTES.medicareMedicaid}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.medicareMedicaid}
            element={
              <MedicareMedicaid
                nextPageRoute={"../congrats"}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.congrats}
            element={
              <Congrats
                headline={
                  "Congratulations! You are eligible for $6,400 Subsidy."
                }
                number={number}
              />
            }
          />
        </Route>

        <Route
          path="/lander-1"
          element={
            <QuizLander
              cityStateResponse={cityStateResponse}
              setNumber={setNumber}
            />
          }
        >
          <Route
            index
            element={
              <CTAQuizLander
                subtitle={`Americans in ${cityStateResponse.city}, ${cityStateResponse.state} with $10,000 or more in credit card debt have a 95% approval rate - financing ends soon.`}
                headline={
                  <h1>
                    New debt reduction plan for Americans, qualifying
                    individuals receive an average debt cancellation of {}
                    <span className="highlighted">$26,352.</span>
                  </h1>
                }
              />
            }
          />
          <Route
            path={ROUTES.income}
            element={
              <IncomeQuizLander
                setNumber={setNumber}
                nextPageRoute={"../" + ROUTES.medicareMedicaid}
                headline={
                  <h1>
                    New debt reduction plan for Americans, qualifying
                    individuals receive an average debt cancellation of {}
                    <span className="highlighted">$26,352.</span>
                  </h1>
                }
              />
            }
          />
          <Route
            path={ROUTES.medicareMedicaid}
            element={
              <MedicareMedicaidQuizLander
                nextPageRoute={"../congrats"}
                headline={
                  <h1>
                    New debt reduction plan for Americans, qualifying
                    individuals receive an average debt cancellation of {}
                    <span className="highlighted">$26,352.</span>
                  </h1>
                }
              />
            }
          />
          <Route
            path={ROUTES.congrats}
            element={
              <CongratsQuizLander
                headline={`Congratulations!!
                `}
                number={number}
              />
            }
          />
        </Route>

        <Route
          path="/lander-2"
          element={
            <QuizLander
              cityStateResponse={cityStateResponse}
              setNumber={setNumber}
            />
          }
        >
          <Route
            index
            element={
              <CTAQuizLander
                subtitle={`Americans in ${cityStateResponse.city}, ${cityStateResponse.state}, have only 3 days to claim their FREE $6400* Subsidy which they can use for Grocery, Rent, Gas and More!`}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.income}
            element={
              <IncomeQuizLander
                setNumber={setNumber}
                nextPageRoute={"../" + ROUTES.medicareMedicaid}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.medicareMedicaid}
            element={
              <MedicareMedicaidQuizLander
                nextPageRoute={"../congrats"}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.congrats}
            element={
              <CongratsQuizLander
                headline={
                  "Congratulations! You are eligible for $6,400 Subsidy."
                }
                number={number}
              />
            }
          />
        </Route>

        <Route
          path="/lander-78"
          element={
            <QuizLander
              cityStateResponse={cityStateResponse}
              setNumber={setNumber}
            />
          }
        >
          <Route
            index
            element={
              <CTAQuizLander
                subtitle={`Americans in ${cityStateResponse.city}, ${cityStateResponse.state}, have only 3 days to claim their FREE $6400* Subsidy which they can use for Grocery, Rent, Gas and More!`}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.income}
            element={
              <IncomeQuizLander
                setNumber={setNumber}
                nextPageRoute={"../" + ROUTES.medicareMedicaid}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.medicareMedicaid}
            element={
              <MedicareMedicaidQuizLander
                nextPageRoute={"../" + ROUTES.healthInsurance}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.healthInsurance}
            element={
              <HealthInsurance
                nextPageRoute={"../" + ROUTES.phoneNumber}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.phoneNumber}
            element={
              <PhoneNumber
                nextPageRoute={"../congrats"}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.congrats}
            element={
              <CongratsQuizLander
                headline={
                  "Congratulations! You are eligible for $6,400 Subsidy."
                }
                number={number}
              />
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export const Timer = () => {
  const Ref = useRef(null);
  const [timer, setTimer] = useState("00:00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    // const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("00:10");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 190);
    return deadline;
  };
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  return <span>{timer}</span>;
};

function Intiator() {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const fetchDomainSetting = async () => {
    const routes = ["lander-1", "lander-2", "lander-78"];
    const pathname = window.location.pathname.split("/")[1];
    const finalizedPath = routes.find((route) => pathname.includes(route));
    const subDomain = window.location.hostname;
    const url = `https://backend.subsidysupportnetwork.org/sites/production?landerName=${
      finalizedPath ? finalizedPath : ""
    }&subDomainName=${subDomain}`;
    // const url = "https://backend.subsidyaid.com/sites/production?landerName=&subDomainName=testing.subsidyaid.com"

    if (window.location.hostname.includes("localhost")) {
      var script = window.document.createElement("script");
      script.src = "/scripts/localScript.js";
      script.async = false;
      document.head.appendChild(script);
    }

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        window.domain_settings = data;
        var script = window.document.createElement("script");
        script.src = "/scripts/scripts.js";
        script.async = false;
        document.head.appendChild(script);
        setScriptLoaded(true);
      })
      .catch((error) => {
        setScriptLoaded(true);
        console.error("There was a problem with your fetch operation:", error);
      });
  };

  useEffect(() => {
    fetchDomainSetting();
  }, []);
  if (!scriptLoaded) return null;
  return <App />;
}

export default Intiator;
